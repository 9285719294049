<template>
  <div>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-container>
          <h2 class="primary--text" v-html="$t('welcome')"></h2>
        </v-container>
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col>
        <div class="testSelectionContainer"
          v-for="(test, index) in availableTests" :key="index"
        >
        <v-container v-if="$vuetify.breakpoint.mdAndUp">
          <TestSelection
            :id="test"
            :key="`testSelection${index}`"
          />
        </v-container>
        <div v-else>
          <TestSelection
            :id="test"
            :key="`testSelection${index}`"
          />
        </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Home',
  components: {
    TestSelection: lazyLoad('components/Cards/TestSelection'),
  },
  computed: {
    ...mapGetters('testMacro', [
      'availableTests',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.testSelectionContainer {
  padding: 0 0 12px 0;
}
</style>
